// @import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);

.stop-watch {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.App {
  font-family: NanumSquare;
  text-align: center;
}
.timer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.time {
  font-size: 20em;
  font-family: 'Digital Numbers', sans-serif;;
}
@media screen and (max-width: 759px) {
  .time {
    font-size: 10em;
  }
}
.right {
  margin-left: 20px;
}
.unit {
  margin-left: 10px;
  font-size: 16px;
}
.button-wrapper {
  display: flex;
  
  justify-content: space-evenly;
  margin-top: 40px;
  width: 90%;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 38px;
}
.button:hover {
  cursor: pointer;
}
.presets{
  margin-top: 40px ;
  a{
    padding: 20px;
  }
}

#timer{
  position: relative;
}

#timer:before,#timer:after{
  position: absolute;
  content: attr(data-text);
  width: 100%;
  height: 100%;
  top:-10;
  left:0;
  color: black;
  opacity: 0.05;
  z-index: -1;
}
