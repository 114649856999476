
nav .branding{
    padding-left: 2rem;
}

#navbarNav, .navbar-nav{
    justify-content: flex-end;
    align-items: flex-end;
}

