$teal: #00b4cf;
$white: #ffffff;

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

// * {
//   box-sizing:border-box;
//   margin:0;
// }

// body {
// 	background: linear-gradient(90deg, #BE8CEF 0%, rgba(61, 46, 232, 0.83) 100%);
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	flex-direction: column;
// 	font-family: 'Montserrat', sans-serif;
//   font-size:10px;
// 	height: 100vh;
// 	margin: -20px 0 50px;
// }

.ContactMe {
    padding-top: 10vh;
    height: 91vh;

    .social-container {
        // margin-top: 30px;

        .social {
            margin-left: 10px;
            margin-right: 10px;
        }


        a {
            color: $white;
        }
    }

    .container {
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
        position: relative;
        overflow: hidden;
        width: 70%;
        max-width: 100%;
        // min-height: 500px;
        min-width: 500px;
        --bs-gutter-x: 0rem;
        top: 5%;

    }

    h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .form-container {
        display: flex;
        // height: 80vh;
    }


    .right-container {
        display: flex;
        flex: 1;
        height: 100%;
        background-color: $white;
        justify-content: center;
        align-items: center;
    }

    .left-container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $teal;
    }

    .left-container p {
        font-size: 0.9rem;
    }

    .right-inner-container {
        width: 70%;
        height: 80%;
        text-align: center;
        padding-top: 19px;
        padding-bottom: 19px;
    }

    .left-inner-container {
        // height: 50%;
        width: 80%;
        text-align: center;
        line-height: 22px;
    }

    input,
    textarea {
        background-color: #eee;
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 100%;
        font-size: 0.8rem;
    }

    input:focus,
    textarea:focus {
        outline: 1px solid $teal;
    }

    button {
        border-radius: 20px;
        border: 1px solid #00b4cf;
        background-color: #00b4cf;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        padding: 12px 45px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: transform 80ms ease-in;
        cursor: pointer;
    }

    button:hover {
        opacity: 0.7;
    }

    @media only screen and (max-width: 600px) {
        .left-container {
            display: none;
        }

        .lg-view {
            display: none;
        }
    }

    @media only screen and (min-width: 600px) {
        .sm-view {
            display: none;
        }
    }

    form p {
        text-align: left;
    }

    form span {
        position: absolute;
        left: 58%;
        // margin-top: 50px;
        // top: 10px;
        // width: 100%;
        font-size: 0.5em;
        color: red;
        margin-top: -5px;
    }


    /* Scrollbar Styling */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #bcb7b7;
    }
}

// .social-container{
//     position: relative;
//     width: 100%;
// }