#root {
    scroll-snap-type: y mandatory;
    overflow: scroll;
    max-height: 100vh;
}

.s-row {
    scroll-snap-align: center;
    position: relative;
}

.corner-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50%;
    margin-right: 5rem;
    margin-bottom: 10rem;
}

.center {
    display: flex !important;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;

    .image {
        align-items: center;

    }
}

.intro_text {
    .mail {
        font-size: 15px;
    }

    font-size: 35px;
    font-family: 'Share Tech Mono';
}

.myname {
    font-weight: bold;
}

.title {
    font-size: 30px;
    font-weight: bolder;
    text-decoration: underline wavy;
}

// .bottom-left {
//     margin: 2em;
//     width: 1em;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     writing-mode: vertical-lr;
//     transform: rotate(180deg);
// }


.bottom-right {
    margin: 2em;
    width: 1em;
    position: absolute;
    bottom: 0;
    right: 0;

    .text {
        bottom: 1.5em;
        position: absolute;
        writing-mode: vertical-lr;
        right: 0;
        font-family: 'Share Tech Mono';

    }
}

.bottom-left {
    margin: 2em;
    width: 1em;
    position: absolute;
    bottom: 0;
    left: 0;

    .text {
        bottom: 1.5em;
        position: absolute;
        writing-mode: vertical-lr;
        right: 0;
        font-family: 'Share Tech Mono';

    }
}

// .top-sticker{
//     position: fixed;
//     top:0;
//     left: 0;
//     overflow: hidden;
//     height: 500px;
//     width: 500px;
//     .triangle{
//         position: absolute;
//         top:0;
//         left: 0;
//         width: 0;
//         height: 0;
//         border-top: 25px solid transparent;
//         border-right: 50px solid #555;
//         border-bottom: 25px solid transparent;
//         transform: rotate(170deg) scale(8);
//     }
// }

// .bottom-sticker{
//     position: fixed;
//     bottom:0;
//     right: 0;
//     overflow: hidden;
//     height: 500px;
//     width: 500px;
//     .triangle {
//         position: absolute;
//         bottom: 99px;
//         right: 0;
//         width: 0;
//         height: 0;
//         border-bottom: 100px solid rgb(8, 206, 250);
//         border-left: 150px solid transparent;
//         transform: scale(3);
//     }
//     div{
//         font-size: 25px;
//         position: absolute;
//         bottom:25px;
//         right: 25px;

//         a{
//             animation: shake 5s infinite ease-in-out;
//         }
//     }
// }

// @keyframes shake {
//     0% { transform: translate(1px, 1px) rotate(0deg); }
//     10% { transform: translate(-1px, -2px) rotate(-1deg); }
//     20% { transform: translate(-3px, 0px) rotate(1deg); }
//     30% { transform: translate(3px, 2px) rotate(0deg); }
//     40% { transform: translate(1px, -1px) rotate(1deg); }
//     50% { transform: translate(-1px, 2px) rotate(-1deg); }
//     60% { transform: translate(-3px, 1px) rotate(0deg); }
//     70% { transform: translate(3px, 1px) rotate(-1deg); }
//     80% { transform: translate(-1px, -1px) rotate(1deg); }
//     90% { transform: translate(1px, 2px) rotate(0deg); }
//     100% { transform: translate(1px, -2px) rotate(-1deg); }
//   }

// p {
//     animation-name: change-color, grow-shrink, expand;
//     animation-duration: 8s, 5s, 5s;
//     animation-iteration-count: infinite;

//     font-family: Avenir;
//     font-size: 26px;
//     text-align: center;
//   }