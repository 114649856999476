.main-div{
  height: 90vh;
}

.login-form{
  background-color: white;
  padding: 0px 19px 19px 19px;
  width: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .inputs{
    padding-top: 10px;
  }

  .nav-item{
    width: 50%;
  }
}

.nav-tabs{
  // padding-bottom: 10px;
  padding-top: 5px;
}
