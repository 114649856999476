body {
	// overflow-y: hidden;`
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@font-face {
	font-family: 'Clock';
	src: url("./assets/fonts/clockicons.ttf");
}


@font-face {
	font-family: 'Branding';
	src: url("./assets/fonts/Fellya.ttf");
}

.branding {
	font-family: Branding !important;
}

// .scroll-animation-container-top {
// 	// z-index: 10000;
// 	top: 8vh;
// 	position: fixed;
// 	left: 50%;

// 	.scroll-animation {
// 		animation: up 1.5s infinite;
// 		-webkit-animation: up 1.5s infinite;

// 		&::before {
// 			top: 23px;
// 			left: 18px;
// 			width: 18px;
// 			height: 18px;
// 			transform: rotate(135deg);
// 		}
// 	}

// }

// .scroll-animation-container-bottom {
// 	bottom: 0;
// 	position: fixed;
// 	left: 50%;

// 	.scroll-animation {
// 		animation: down 1.5s infinite;
// 		-webkit-animation: down 1.5s infinite;

// 		&::before {
// 			top: 15px;
// 			left: 18px;
// 			width: 18px;
// 			height: 18px;
// 			transform: rotate(-45deg);
// 		}
// 	}
// }


// .scroll-animation {

// 	display: flex;
// 	align-items: center;
// 	width: 60px;
// 	height: 60px;
// 	border: 2px solid #333;
// 	border-radius: 50%;
// 	position: relative;

// 	&::before {
// 		content: '';
// 		position: absolute;
// 		border-left: 2px solid #333;
// 		border-bottom: 2px solid #333;
// 	}
// }

// @keyframes down {
// 	0% {
// 		transform: translate(0);
// 	}

// 	20% {
// 		transform: translateY(15px);
// 	}

// 	40% {
// 		transform: translate(0);
// 	}
// }

// @-webkit-keyframes down {
// 	0% {
// 		transform: translate(0);
// 	}

// 	20% {
// 		transform: translateY(15px);
// 	}

// 	40% {
// 		transform: translate(0);
// 	}
// }

// @keyframes up {
// 	0% {
// 		transform: translate(0);
// 	}

// 	20% {
// 		transform: translateY(-15px);
// 	}

// 	40% {
// 		transform: translate(0);
// 	}
// }

// @-webkit-keyframes up {
// 	0% {
// 		transform: translate(0);
// 	}

// 	20% {
// 		transform: translateY(-15px);
// 	}

// 	40% {
// 		transform: translate(0);
// 	}
// }