.experience_text {
  font-size: 25px;
  font-family: 'Share Tech Mono';

}

.date {
  font-size: 16px;
}

.position{
  font-size: 20px;
  font-weight: normal;
}

.description{
  font-size: 18px;
  font-weight: normal;
}

.image{
  height: 400px;
}